var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "m-container",
        [
          _c("page-title", {
            staticClass: "mt-10",
            attrs: { "page-title": !_vm.$language ? "Students" : "শিক্ষার্থী" },
          }),
          _c("student-list-component", {
            staticClass: "mt-4 ml-2",
            attrs: { "course-id": _vm.courseId },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }