<template>
  <div>
    <m-container>
      <page-title
        :page-title="!$language ? 'Students' : 'শিক্ষার্থী'"
        class="mt-10"
      ></page-title>

      <student-list-component
        class="mt-4 ml-2"
        :course-id="courseId"
      ></student-list-component>
    </m-container>
  </div>
</template>

<script>
import StudentListComponent from "#ecf/student/components/StudentListComponent";
export default {
  name: "StudentListPage",
  components: { StudentListComponent },
  props: {
    courseId: String
  },
  computed: {
    course() {
      return this.$store.state.course;
    }
  }
};
</script>

<style lang="scss">
.student-list {
  h2 {
    font-family: Roboto, serif;
    font-size: 22px;
    font-weight: 500;
    color: $text;
    margin-bottom: 10px;
  }
  h3 {
    font-family: Roboto, serif;
    font-size: 18px;
    font-weight: 500;
    color: $text;
    margin-bottom: 4px;
  }
  p {
    font-family: Roboto, serif;
    margin-bottom: 14px !important;
    color: #000000;
    text-align: justify;
  }
}
.answer-grid {
  display: grid;
  grid-template-columns: auto auto;
}
</style>
