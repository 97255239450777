<template>
  <!--  <v-container>-->
  <div
    v-if="isStudentLoading || student_list.length === 0"
    class="d-flex justify-center pa-4"
  >
    <v-progress-circular indeterminate color="primary"></v-progress-circular>
  </div>
  <v-simple-table v-else>
    <thead>
      <tr v-if="!$language">
        <th>Avatar</th>
        <th>Name</th>
        <th>Batch</th>
        <th>Date Added</th>
        <th></th>
      </tr>
      <tr v-else>
        <th>ছবি</th>
        <th>নাম</th>
        <th>ব্যাচ</th>
        <th>তারিখ</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(student, index) in student_list[0]" :key="index">
        <td><v-icon>person</v-icon></td>
        <td>{{ student_list[2][student.student_id] }}</td>
        <td>{{ student_list[1][student.batch_id]["batch_title"] }}</td>
        <td>{{ student.join_date }}</td>
        <td>
          <div>
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="elevation-0" v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <v-list-item-title
                    v-if="!$language"
                    @click="removeStudent(student)"
                    >Remove</v-list-item-title
                  >
                  <v-list-item-title v-else @click="removeStudent(student)"
                    >বাতিল করুন</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </td>
      </tr>
    </tbody>
  </v-simple-table>

  <!--  </v-container>-->
</template>

<script>
export default {
  name: "StudentListComponent",
  props: {
    courseId: String,
    partner_code: String
  },
  data() {
    return {
      isStudentLoading: false,
      zod: {}
    };
  },
  computed: {
    student_list() {
      return this.$store.getters["student/students"][this.courseId] ?? [];
    }
  },
  async mounted() {
    this.isStudentLoading = true;
    await this.fetchStudentList();
    this.isStudentLoading = false;
  },
  methods: {
    async removeStudent(student) {
      try {
        const data = {
          partner_code: this.$route.params.partner_code,
          course_id: this.courseId,
          batch_id: student.batch_id,
          student_id: student.student_id
        };
        this.isStudentLoading = true;
        await this.$store.dispatch("batch/removeStudent", data);
        await this.fetchStudentList();
        this.isStudentLoading = false;
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
    async fetchStudentList() {
      try {
        this.zod = await this.$store.dispatch("student/list", {
          courseId: this.courseId,
          partner_code: this.partner_code
        });
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    }
  }
};
</script>

<style scoped></style>
