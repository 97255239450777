var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isStudentLoading || _vm.student_list.length === 0
    ? _c(
        "div",
        { staticClass: "d-flex justify-center pa-4" },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", color: "primary" },
          }),
        ],
        1
      )
    : _c("v-simple-table", [
        _c("thead", [
          !_vm.$language
            ? _c("tr", [
                _c("th", [_vm._v("Avatar")]),
                _c("th", [_vm._v("Name")]),
                _c("th", [_vm._v("Batch")]),
                _c("th", [_vm._v("Date Added")]),
                _c("th"),
              ])
            : _c("tr", [
                _c("th", [_vm._v("ছবি")]),
                _c("th", [_vm._v("নাম")]),
                _c("th", [_vm._v("ব্যাচ")]),
                _c("th", [_vm._v("তারিখ")]),
                _c("th"),
              ]),
        ]),
        _c(
          "tbody",
          _vm._l(_vm.student_list[0], function (student, index) {
            return _c("tr", { key: index }, [
              _c("td", [_c("v-icon", [_vm._v("person")])], 1),
              _c("td", [
                _vm._v(_vm._s(_vm.student_list[2][student.student_id])),
              ]),
              _c("td", [
                _vm._v(
                  _vm._s(_vm.student_list[1][student.batch_id]["batch_title"])
                ),
              ]),
              _c("td", [_vm._v(_vm._s(student.join_date))]),
              _c("td", [
                _c(
                  "div",
                  [
                    _c(
                      "v-menu",
                      {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        { staticClass: "elevation-0" },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-dots-vertical"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _c(
                          "v-list",
                          [
                            _c(
                              "v-list-item",
                              [
                                !_vm.$language
                                  ? _c(
                                      "v-list-item-title",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeStudent(student)
                                          },
                                        },
                                      },
                                      [_vm._v("Remove")]
                                    )
                                  : _c(
                                      "v-list-item-title",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeStudent(student)
                                          },
                                        },
                                      },
                                      [_vm._v("বাতিল করুন")]
                                    ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ])
          }),
          0
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }